import api from "../api";
import { localStorageCache } from "./localStorageHelper";
import { queryClientPersister } from "./queryClient";
import refreshAsync from "./refreshAsync";

export default function signOut() {
  return api
    .logout()
    .then(() => queryClientPersister.removeClient())
    .then(() => localStorageCache.clear())
    .then(refreshAsync);
}
