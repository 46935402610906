import { Anchor, Box, Flex, Paper, Title } from "@mantine/core";
import {
  orgPath,
  useCurrentOrgExternalCodeWithFallback,
} from "../../state/useOrg";
import LayoutV2 from "../LayoutV2/LayoutV2";

export function NoDataPage(): React.JSX.Element {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  return (
    <LayoutV2 bg="gray.0">
      <Flex justify="center" mih={500} pt={80} h="calc(100vh - 60px)">
        <Paper shadow="md" p="xl" maw={600} lh="sm" mah={200}>
          <Title>No Data Available</Title>
          <Box pt="sm">
            No data is available yet. You can{" "}
            <Anchor
              href={orgPath(
                "/manage-org/aws-connection",
                orgExternalCode ?? "",
              )}
            >
              configure your AWS connection
            </Anchor>{" "}
            to start ingesting data. If you&apos;ve already done that, you
            should start seeing data here within a day or two.
          </Box>
        </Paper>
      </Flex>
    </LayoutV2>
  );
}
