import React, { useCallback, useMemo } from "react";
import { localStorageCache } from "../modules/localStorageHelper";
import {
  GlobalViewStateContext,
  GlobalViewStateContextType,
  ColorMode,
  DEFAULT_COLOR_MODE,
  invertColorMode,
} from "./GlobalViewStateContext";

interface Props {
  children: React.ReactNode;
}

export function GlobalViewStateProvider({ children }: Props) {
  const [navElement, setNavElement] = React.useState<HTMLElement | null>(null);

  const [colorMode, setColorModeInner] = localStorageCache.useState<ColorMode>(
    "color-mode",
    DEFAULT_COLOR_MODE,
  );

  const setColorMode = useCallback(
    (v?: ColorMode) => {
      const newMode = v || invertColorMode(colorMode);
      document.querySelector("html")?.setAttribute("data-bs-theme", newMode);
      setColorModeInner(newMode);
    },
    [colorMode, setColorModeInner],
  );

  const invertedColorMode = invertColorMode(colorMode);

  const value: GlobalViewStateContextType = useMemo(
    () => ({
      navElement,
      setNavElement,
      colorMode,
      setColorMode,
      invertedColorMode,
    }),
    [navElement, colorMode, setColorMode, invertedColorMode],
  );

  return (
    <GlobalViewStateContext.Provider value={value}>
      {children}
    </GlobalViewStateContext.Provider>
  );
}

export default GlobalViewStateProvider;
