import { ComponentType } from "react";
import Redirect from "../components/Redirect";
import { useUser } from "../state/useUser";

interface RedirectTestParams {
  userAuthed: boolean;
  userUnauthed: boolean;
}

export function redirectUnless(
  to: string,
  test: (params: RedirectTestParams) => boolean,
) {
  return function <PropType extends object>(
    Wrapped: ComponentType<PropType>,
  ): ComponentType<PropType & { children?: React.ReactNode }> {
    return function RedirectUnless(
      props: PropType & { children?: React.ReactNode },
    ) {
      const userCtx = useUser();
      if (userCtx.userLoading) {
        return null;
      }
      return test(userCtx) ? <Wrapped {...props} /> : <Redirect to={to} />;
    };
  };
}

export const redirectIfAuthed = redirectUnless(
  "/dashboard",
  ({ userUnauthed }) => userUnauthed,
);

export const redirectIfUnauthed = redirectUnless(
  "/",
  ({ userAuthed }) => userAuthed,
);
