import { useContext, useEffect, ComponentType } from "react";
import { ScreenLoaderContext } from "./ScreenLoaderContext";

export default function useScreenLoader() {
  return useContext(ScreenLoaderContext);
}

export function withScreenLoaderMount() {
  return function <P extends object>(Component: ComponentType<P>) {
    return function WrappedComponent(props: P) {
      const { setState: setLoading } = useScreenLoader();

      useEffect(() => {
        setLoading(true);
        return () => setLoading(false);
      }, [setLoading]);

      return <Component {...props} />;
    };
  };
}
