import {
  createTheme,
  DEFAULT_THEME,
  MantineThemeOverride,
  mergeMantineTheme,
} from "@mantine/core";

const fontFamily = [
  "Inter",
  "system-ui",
  "-apple-system",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  '"Noto Sans"',
  '"Liberation Sans"',
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
].join(", ");
export const theme: MantineThemeOverride = createTheme({
  primaryColor: "indigo",
  primaryShade: 4,
  defaultRadius: "md",
  fontFamily,
  headings: {
    fontFamily,
  },
});

export const wholeTheme = mergeMantineTheme(DEFAULT_THEME, theme);
