import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Anchor, AppShell, Box, Text } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { ComponentProps } from "react";
import { helpEmail } from "../../modules/contacts";
import HeaderV2 from "./HeaderV2";
import SideNavV2 from "./SideNavV2";

export type LayoutV2Props = {
  children: React.ReactNode;
  bg?: ComponentProps<typeof AppShell.Main>["bg"];
};

export default function LayoutV2({
  bg,
  children,
}: LayoutV2Props): React.JSX.Element {
  const pinned = useHeadroom({ fixedAt: 120 });
  const { globalBetaBanner } = useFlags();
  console.log({ globalBetaBanner });

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned }}
      navbar={{ width: 300, breakpoint: 1 }}
      c="gray.8"
    >
      <HeaderV2 />
      <SideNavV2 />
      <AppShell.Main bg={bg ?? "gray.0"}>
        <Box maw={1200}>
          {globalBetaBanner && (
            <Alert
              title={
                <Text fz="md" fw="bold" component="span">
                  We&apos;re in beta!
                </Text>
              }
              color="red"
              m="lg"
              mb={0}
              icon={<FontAwesomeIcon icon={faCircleExclamation} size="1x" />}
              variant="light"
              lh="lg"
            >
              Data presented here may be wrong or misleading. While we strive
              for complete accuracy, take these numbers with a grain of salt
              until we&apos;re out of beta. Let us know (
              <Anchor href={"mailto:" + helpEmail} fz="sm">
                {helpEmail}
              </Anchor>
              ) if you notice anything off.
            </Alert>
          )}
          {children}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
