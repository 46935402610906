import React from "react";
import { ScreenLoaderContext } from "./ScreenLoaderContext";
import { Toggle } from "./useToggle";

interface Props {
  children: React.ReactNode;
}

export function ScreenLoaderProvider({ children }: Props) {
  const [isOn, setState] = React.useState(false);

  const value: Toggle = React.useMemo(
    () => ({
      isOn,
      isOff: !isOn,
      setState,
      turnOn: () => setState(true),
      turnOff: () => setState(false),
      toggle: () => setState((prev) => !prev),
    }),
    [isOn],
  );

  return (
    <ScreenLoaderContext.Provider value={value}>
      {children}
    </ScreenLoaderContext.Provider>
  );
}

export default ScreenLoaderProvider;
