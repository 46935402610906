import { Dayjs } from "dayjs";
import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";

export type DDTotals = {
  amazonNetCost: number;
  discrepancies: number;
};
export type DiscountDiscrepancy = {
  amazonNetCost: number;
  expectedCost: number;
  retailCost: number;
  lineItemUsageTypeWithoutRegion: string;
  usageAmount: number;
  pricingUnit: string;
  productRegionCode: string;
  hasDiscrepancy: boolean;
  hasMapCredits: boolean;
};
export const discountDiscrepancyFactory = Factory.define<DiscountDiscrepancy>(
  () => ({
    amazonNetCost: 1000000,
    expectedCost: 1000000,
    retailCost: 2000000,
    lineItemUsageTypeWithoutRegion: "1",
    usageAmount: 123456,
    pricingUnit: "gb-hrs",
    productRegionCode: "eu-west-1",
    hasDiscrepancy: false,
    hasMapCredits: false,
  }),
);
export type UsageTypeDDHierarchy = {
  usageTypeName: string;
  totals: DDTotals;
  discountDiscrepancies: DiscountDiscrepancy[];
};
export const usageTypeDDHierarchyFactory = Factory.define<UsageTypeDDHierarchy>(
  () => ({
    usageTypeName: "timed-storage",
    totals: { amazonNetCost: 10000, discrepancies: 1 },
    discountDiscrepancies: [
      discountDiscrepancyFactory.build({ productRegionCode: "eu-west-1" }),
      discountDiscrepancyFactory.build({ productRegionCode: "eu-west-2" }),
    ],
  }),
);
export type ServiceDDHierarchy = {
  serviceName: string;
  totals: DDTotals;
  usageTypes: UsageTypeDDHierarchy[];
};
export const serviceDDHierarchyFactory = Factory.define<ServiceDDHierarchy>(
  () => ({
    serviceName: "S3 Requests",
    totals: { amazonNetCost: 20000, discrepancies: 2 },
    usageTypes: [
      usageTypeDDHierarchyFactory.build({ usageTypeName: "timed-storage" }),
      usageTypeDDHierarchyFactory.build({ usageTypeName: "tier-1-requests" }),
    ],
  }),
);
export type ServiceGroupDDHierarchy = {
  serviceGroupName: string;
  services: ServiceDDHierarchy[];
  totals: DDTotals;
};
export const serviceGroupDDHierarchyFactory =
  Factory.define<ServiceGroupDDHierarchy>(() => ({
    serviceGroupName: "S3",
    totals: { amazonNetCost: 40000, discrepancies: 4 },
    services: [
      serviceDDHierarchyFactory.build({ serviceName: "S3 Requests" }),
      serviceDDHierarchyFactory.build({ serviceName: "S3 Storage" }),
    ],
  }));
export type DiscountDiscrepancyHierarchy = ServiceGroupDDHierarchy[];
export const getReconciliationDiscountDiscrepancies = ({
  monthStartDate: month_start_date,
  orgExternalCode: orgExternalCode,
}: {
  monthStartDate: Dayjs;
  orgExternalCode: string;
}) =>
  apiRequest.get<DiscountDiscrepancyHierarchy>(
    `/api/v1/organizations/${orgExternalCode}/reconciliation/discount_discrepancies`,
    {
      params: { month_start_date: month_start_date.toISOString().slice(0, 10) },
    },
  );

export type ReconciliationAvailableMonths = { availableMonths: string[] };
export const getReconciliationAvailableMonths = ({
  orgExternalCode,
}: {
  orgExternalCode: string;
}) =>
  apiRequest.get<ReconciliationAvailableMonths>(
    `/api/v1/organizations/${orgExternalCode}/reconciliation/available_months`,
  );
