import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProviderProps } from "@tanstack/react-query-persist-client";

// Set up the default options for tanstack query.
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,

      // Per the docs[0], we need to set garbage collection to a long period in
      // order for localStorage peristence to work.
      //
      // [0] https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient#how-it-works
      gcTime: Infinity,
    },
  },
});
export const queryClientPersister = createSyncStoragePersister({
  storage: window.localStorage,
});
export const queryClientPersistOptions: PersistQueryClientProviderProps["persistOptions"] =
  {
    persister: queryClientPersister,
    maxAge: 1000 * 60 * 60 * 24 * 30, // 30 days

    // Increment this string to bust the query cache.
    // TODO: maybe use the build hash for this so the cache gets busted every
    // deploy.
    buster: "1",
  };
