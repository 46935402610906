import React from "react";

export interface Toggle {
  turnOff: () => void;
  turnOn: () => void;
  toggle: () => void;
  setState: (state: boolean) => void;
  isOn: boolean;
  isOff: boolean;
}

/**
 * @param {boolean=} initial
 * @return {Toggle}
 */
export function useToggle(initial: boolean = false): Toggle {
  const [isOn, setState] = React.useState(initial);

  return React.useMemo(
    () => ({
      isOn,
      isOff: !isOn,
      setState,
      turnOn: () => setState(true),
      turnOff: () => setState(false),
      toggle: () => setState(!isOn),
    }),
    [isOn],
  );
}

export default useToggle;
