import { Factory } from "fishery";

export type Contract = {
  contractType: string;
  detailUrl: string;
  executedAt: string;
  id: string;
  isImporting: boolean;
  isPrimary: boolean;
  name: string;
  periodLabel: string;
};

export const ContractFactory = Factory.define<Contract>(() => ({
  contractType: "Private Pricing",
  detailUrl: "/org/sy55q/contracts/3",
  executedAt: "2024-04-30T00:00:00+00:00",
  id: "3",
  isImporting: false,
  isPrimary: false,
  name: "CCC copy contract",
  periodLabel: "2024 - 2027",
  standardRenewalEvent: {
    at: "2026-11-01T00:00:00+00:00",
    contractPeriodElapsedPercentage: 0.83,
    relevant: true,
  },
}));
