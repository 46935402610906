import { Box, useMantineTheme } from "@mantine/core";
import React from "react";
import Plot from "react-plotly.js";

type MonthlyDataPoint = {
  month: string; // ISO 8601 date string, e.g. "2021-01-01"
  yValue: number; // e.g. 12345.67
};

export type MiniChartProps = {
  widthPx: number;
  heightPx: number;
  data: MonthlyDataPoint[];
  label: string;
  hoverTemplate?: string;
};

export default function MiniChart({
  widthPx,
  heightPx,
  data,
  label,
  hoverTemplate,
}: MiniChartProps): React.JSX.Element {
  const allYValues = data.map((d) => d.yValue);
  const maxYValue = Math.max(...allYValues);
  const minYValue = Math.min(...allYValues);
  const spread = Math.abs(maxYValue - minYValue);

  // If the max y value is below 1, then we probably just have no data for this
  // period.  Set the top of the chart to 1 so the flat line is placed at
  // the bottom of the graph.
  //
  // Otherwise, add 10% to the max y value so the line isn't right at the top of
  // the chart.
  const chartMax = Math.max(maxYValue + spread * 0.1, 1);

  // We don't want the bottom of the chart to be at zero.  This is a chart to
  // show trends, not an absolute chart. So we'll set the bottom of the chart
  // to be 10% below the minimum y value
  const chartMin = minYValue - spread * 0.1;

  const theme = useMantineTheme();

  // Normally, we don't want to show a dot for each data point.  But if there's
  // only one data point, we'll show a dot.
  const markerSize = data.length > 1 ? 1 : 5;

  return (
    <Box>
      <Plot
        config={{ editable: false, displayModeBar: false }}
        layout={{
          showlegend: false,
          title: {
            text: label,
            y: 0.01,
            x: 0.07,
            font: {
              color: theme.primaryColor,
              size: 10,
              family: theme.fontFamily,
              weight: 600,
            },
          },
          margin: { t: 2, r: 0, l: 0, b: 17 },
          height: heightPx,
          width: widthPx,
          grid: {},
          yaxis: {
            range: [chartMin, chartMax],
            fixedrange: true,
            showgrid: false,
            zeroline: false,
          },
          xaxis: {
            fixedrange: true,
            showgrid: false,
            zeroline: false,
            showticklabels: false,
          },
        }}
        data={[
          {
            hovertemplate: hoverTemplate ?? "%{y:.0f}<extra>%{x}</extra>",
            x: data.map((s) => s.month),
            y: allYValues,
            type: "scatter",
            marker: { color: "black", size: markerSize },
            line: { color: theme.primaryColor, width: 2.5 },
          },
        ]}
      />
    </Box>
  );
}
