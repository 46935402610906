import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";
import { PaginationResponse } from "./commonTypes";
import { Contract, ContractFactory } from "./contracts";

export type Credit = {
  id: string;
  amount: number;
  obtainedOn: string;
  eligibleOn: string;
  missedOn: string;
  requirementsMd: string;
  contract: Contract;
  name: string;
};

export const creditsFactory = Factory.define<Credit>(() => ({
  id: "1",
  amount: 100,
  obtainedOn: "2021-01-01",
  eligibleOn: "2021-01-01",
  missedOn: "2021-01-01",
  requirementsMd:
    "Must be a **full** moon, in the year of the *rabbit*, with at least $3m in S3 spend.",
  name: "credit",
  contract: ContractFactory.build(),
}));

export const getCredits = ({
  orgExternalCode,
}: {
  orgExternalCode: string;
}) => {
  return apiRequest.get<PaginationResponse<Credit>>(
    `/api/v1/organizations/${orgExternalCode}/contracts/credits`,
  );
};
