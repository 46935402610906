import { MantineProvider } from "@mantine/core";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { HelmetProvider } from "react-helmet-async";
import Toaster from "../components/uikit/Toaster.jsx";
import "../modules/dayConfig.js";
import {
  queryClient,
  queryClientPersistOptions,
} from "../modules/queryClient.js";
import GlobalViewStateProvider from "../state/GlobalViewStateProvider";
import { ScreenLoaderProvider } from "../state/ScreenLoaderProvider";
import { UserProvider } from "../state/UserProvider.jsx";
import { AppRouter } from "./AppRouter.js";
import config from "./config.js";
import { theme } from "./theme.js";

export default function App() {
  return (
    <LDProvider clientSideID={config.launchDarklyClientSideId}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={queryClientPersistOptions}
      >
        <GlobalViewStateProvider>
          <Toaster />
          <UserProvider>
            <ScreenLoaderProvider>
              <HelmetProvider>
                <MantineProvider theme={theme}>
                  <AppRouter />
                </MantineProvider>
              </HelmetProvider>
            </ScreenLoaderProvider>
          </UserProvider>
        </GlobalViewStateProvider>
      </PersistQueryClientProvider>
    </LDProvider>
  );
}
