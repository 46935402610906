import { apiRequest } from "../modules/apiRequest";

export type EdrMonth = {
  month: string;
  effectiveDiscountRate: number;
};

export const getEdrTrailing = ({
  trailingMonths,
  orgExternalCode,
}: {
  orgExternalCode: string;
  trailingMonths: number;
}) => {
  return apiRequest.get<EdrMonth[]>(
    `/api/v1/organizations/${orgExternalCode}/effective_discount_rate/trailing`,
    {
      params: { trailing_months: trailingMonths },
    },
  );
};
