import { apiRequest } from "../modules/apiRequest";

export type MonthlyComparisonChartData = {
  // Required identifier
  month: string;
  // Required core fields
  grossCharge: number;
  netCharge: number;
  usage: number;
  credits: number;
  marketplaceCost: number;
  // Optional additional fields
  tax?: number;
  savingsPlanUpfrontFee?: number;
  savingsPlanRecurringFee?: number;
  support?: number;
  shortfallFees?: number;
  serviceUsageCharges?: number;
  supplementalCharges?: number;
  savingsPlanNegation?: number;
  contractualCrossServiceDiscount?: number;
  contractualServiceDiscounts?: number;
  refund?: number;
  savings?: number;
};

export type MonthlyComparisonChartResponse = {
  data: MonthlyComparisonChartData[];
};

export const getMonthlyComparisonChartData = ({
  orgExternalCode,
  startDate,
  endDate,
  includeLinkedAccounts,
  includePrivatePricing,
}: {
  orgExternalCode: string;
  startDate: Date;
  endDate: Date;
  includeLinkedAccounts: boolean;
  includePrivatePricing: boolean;
}) => {
  let aggregation: string;
  if (includeLinkedAccounts) {
    if (includePrivatePricing) {
      aggregation = "linked-private";
    } else {
      aggregation = "linked";
    }
  } else {
    if (includePrivatePricing) {
      aggregation = "payer-private";
    } else {
      aggregation = "payer";
    }
  }

  return apiRequest.get<MonthlyComparisonChartResponse>(
    `/api/v1/organizations/${orgExternalCode}/reconciliation/monthly_comparison/chart`,
    {
      params: {
        aggregation,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
      },
    },
  );
};
