import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";
import { PaginationResponse } from "./commonTypes";

type RoleAccessOption = "read" | "write";
export type OrgRoleAccess = {
  orgsettings: RoleAccessOption[];
  orgmembers: RoleAccessOption[];
  curingest: RoleAccessOption[];
  contractmanager: RoleAccessOption[];
  contracts: RoleAccessOption[];
  commitments: RoleAccessOption[];
  billing: RoleAccessOption[];
};
export type Organization = {
  externalCode: string;
  key: string;
  name: string;
};
export const organizationFactory = Factory.define<Organization>(() => ({
  externalCode: "abc123",
  key: "key",
  name: "Some Org",
}));

export const getOrganization = ({ externalCode }: { externalCode: string }) => {
  return apiRequest.get<Organization>(`/api/v1/organizations/${externalCode}`);
};

export const getSupervisedOrganizations = () => {
  return apiRequest.get<PaginationResponse<Organization>>(
    "/api/v1/me/supervised_organizations",
  );
};
