import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faCalendarCheck,
  faChevronDown,
  faCircleDollarToSlot,
  faCircleDot,
  faFileLines,
  faHandshake,
  faMoneyBill1,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppShell,
  Divider,
  ElementProps,
  Menu,
  NavLink,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";
import { orgPath, useCurrentOrg } from "../../state/useOrg";
import useUser from "../../state/useUser";
import OrgDropdownMenu from "./OrgDropdownMenu";

type NavbarIconProps = { icon: IconProp } & ElementProps<typeof ThemeIcon>;
function BoxIcon({
  icon,
  ...themIconProps
}: NavbarIconProps): React.JSX.Element {
  return (
    <ThemeIcon
      w="md"
      h="md"
      color="indigo.0"
      c="gray.8"
      radius="sm"
      fz="sm"
      mr="8"
      {...themIconProps}
    >
      <FontAwesomeIcon icon={icon} />
    </ThemeIcon>
  );
}

function OrgDropdownOption({ name }: { name: string }): React.JSX.Element {
  return (
    <AppShell.Section>
      <Menu shadow="sm" closeOnItemClick={false}>
        <Menu.Target>
          <NavLink
            label={name}
            leftSection={<BoxIcon icon={faBuilding} />}
            rightSection={<FontAwesomeIcon icon={faChevronDown} />}
            component="div"
          />
        </Menu.Target>
        <OrgDropdownMenu />
      </Menu>
    </AppShell.Section>
  );
}

function NavbarHeader({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  return (
    <AppShell.Section>
      <Text fz="xs" c="gray.5" py="8" px="sm" fw="bold">
        {children}
      </Text>
    </AppShell.Section>
  );
}

type NavOptionProps = {
  icon: IconProp;
  children: React.ReactNode;
  href: string;
};
function NavOption({
  children,
  icon,
  href,
}: NavOptionProps): React.JSX.Element {
  return (
    <AppShell.Section>
      <NavLink
        label={children}
        leftSection={<BoxIcon icon={icon} />}
        fw="normal"
        component={ReactRouterNavLink}
        to={href}
      />
    </AppShell.Section>
  );
}

function NavDivider(): React.JSX.Element {
  return <Divider my="sm" />;
}

export default function SideNavV2(): React.JSX.Element {
  const { data: currentOrg } = useCurrentOrg();
  const { user } = useUser();
  const isSupervisingOrg =
    user?.supervisingOrganizations && user.supervisingOrganizations.length > 0;
  const { creditsPage } = useFlags();

  return (
    <AppShell.Navbar px="xs" py="lg">
      {currentOrg && (
        <>
          <OrgDropdownOption name={currentOrg?.name} />
          <NavDivider />
          {isSupervisingOrg && (
            <>
              <NavbarHeader>Organizations</NavbarHeader>
              <NavOption icon={faCircleDot} href={"/supervised-organizations"}>
                All Organizations
              </NavOption>
            </>
          )}
          <NavbarHeader>Reconciliation</NavbarHeader>
          <NavOption
            icon={faMoneyBill1}
            href={orgPath(
              "/reconciliation/discount-discrepancies",
              currentOrg.externalCode.toString(),
            )}
          >
            Discount Discrepancies
          </NavOption>
          <NavOption
            icon={faCalendarCheck}
            href={orgPath(
              "/reconciliation/monthly",
              currentOrg.externalCode.toString(),
            )}
          >
            Monthly Summary
          </NavOption>
          <NavDivider />
          <NavbarHeader>Contract Management</NavbarHeader>
          <NavOption
            icon={faHandshake}
            href={orgPath(
              "/contracts/commitments",
              currentOrg.externalCode.toString(),
            )}
          >
            Commitments
          </NavOption>
          <NavOption
            icon={faFileLines}
            href={orgPath(
              "/contracts/list",
              currentOrg.externalCode.toString(),
            )}
          >
            Contracts
          </NavOption>
          {creditsPage && (
            <NavOption
              href={orgPath(
                "/contracts/credits",
                currentOrg.externalCode.toString(),
              )}
              icon={faCircleDollarToSlot}
            >
              Credits
            </NavOption>
          )}
        </>
      )}
    </AppShell.Navbar>
  );
}
