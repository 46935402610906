import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor, Box, Button, Drawer, Group, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { truncate } from "lodash";
import React, { ComponentProps, useMemo, useState } from "react";
import api from "../../../api";
import { Credit } from "../../../apiQueries/credits";
import { Organization } from "../../../apiQueries/organizations";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import Table from "../../../components/Table/Table";
import Markdown from "../../../components/uikit/Markdown";
import ScreenLoader from "../../../components/uikit/ScreenLoader";
import dayjs from "../../../modules/dayConfig";
import { abbreviateNumber } from "../../../modules/formatters";
import { orgPath, useCurrentOrg } from "../../../state/useOrg";
import { appHref } from "../../../app/appHref";

const dateFormatter = (date: string) =>
  date ? dayjs(date).format("MMM D, YYYY") : "";
const columnHelper = createColumnHelper<Credit>();

export type CreditsListPagePresentationProps = {
  credits: Credit[];
  currentOrg: Organization;

  // Used only for testing.  Forces the drawer to be open and showing the given
  // credit.
  forceDrawerOpen?: Credit;
};
export function CreditsListPagePresentation({
  credits,
  currentOrg,
  forceDrawerOpen,
}: CreditsListPagePresentationProps): React.JSX.Element {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(!!forceDrawerOpen);

  // The credit that is currently being shown in the drawer.
  const [drawerCredit, setDrawerCredit] = useState<Credit | null>(
    forceDrawerOpen ?? null,
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {}),
      columnHelper.accessor("amount", {
        cell: (info) => <span>$ {abbreviateNumber(info.getValue())}</span>,
      }),
      columnHelper.accessor("missedOn", {
        header: "Due Date",
        cell: (info) => dateFormatter(info.getValue()),
      }),
      columnHelper.accessor("obtainedOn", {
        header: "Obtained On",
        cell: (info) => {
          return dateFormatter(info.getValue());
        },
      }),
      columnHelper.accessor("eligibleOn", {
        header: "Eligible On",
        cell: (info) => dateFormatter(info.getValue()),
      }),
      columnHelper.accessor("contract", {
        header: "Contract",
        cell: (info) => {
          const contract = info.getValue();
          return (
            <Anchor
              href={appHref(
                orgPath(`/contracts/${contract.id}`, currentOrg.externalCode),
              )}
            >
              {truncate(contract.name, { length: 12, omission: "..." })}
            </Anchor>
          );
        },
      }),
      columnHelper.accessor("requirementsMd", {
        header: "Requirements",
        cell: (info) => (
          <Anchor
            component="button"
            onClick={() => {
              setDrawerCredit(info.row.original);
              openDrawer();
            }}
          >
            View
          </Anchor>
        ),
      }),
    ],
    [currentOrg.externalCode, openDrawer],
  );

  return (
    <LayoutV2>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        title={
          // This is a span because the Drawer component already wraps this in
          // an <h2>.
          <Text c="gray.9" fz="h2" fw="bold" component="span">
            {drawerCredit?.name}
          </Text>
        }
        position="right"
      >
        <Box c="gray.7" pt="xs">
          <Title order={3} mb="sm">
            Requirements
          </Title>
          <Markdown
            syntaxHighlight={false}
            className={""}
            components={{
              p: (props: ComponentProps<typeof Text>) => <Text {...props} />,
              em: (props: ComponentProps<"em">) => <i {...props} />,
            }}
          >
            {drawerCredit?.requirementsMd}
          </Markdown>
        </Box>
      </Drawer>
      <Box p="xl" bg="gray.0" style={{ overflowX: "scroll" }}>
        <Group justify="space-between">
          <Title order={1}>Credits</Title>
          <Button
            component="a"
            href={
              (api.axios.getUri() ?? "") +
              `/api/v1/organizations/${currentOrg.externalCode}/contracts/credits/csv`
            }
          >
            <FontAwesomeIcon icon={faDownload} />
            <Box mx="sm">Export as CSV</Box>
          </Button>
        </Group>
        <Table
          tanstackTableOptions={{
            columns,
            data: credits,
            getCoreRowModel: getCoreRowModel(),
          }}
        />
      </Box>
    </LayoutV2>
  );
}

export default function CreditsListPage(): React.JSX.Element {
  const { data: currentOrg } = useCurrentOrg();

  const { data: credits } = useQuery({
    queryKey: ["org", currentOrg?.externalCode, "credits"],
    queryFn: () =>
      api.getCredits({ orgExternalCode: currentOrg?.externalCode ?? "" }),
    enabled: !!currentOrg,
  });

  if (!credits || !currentOrg) {
    return (
      <LayoutV2>
        <ScreenLoader />
      </LayoutV2>
    );
  }

  return (
    <CreditsListPagePresentation
      credits={credits.items}
      currentOrg={currentOrg}
    />
  );
}
