import React from "react";
import { User } from "../apiQueries/users";

export const STORAGE_KEY = "user";

export interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  userLoading: boolean;
  userError: Error | null;
  userAuthed: boolean;
  userUnauthed: boolean;
}

export const UserContext = React.createContext<UserContextType>({
  user: null,
  setUser: () => {},
  userLoading: false,
  userError: null,
  userAuthed: false,
  userUnauthed: true,
});
