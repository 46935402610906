import { useContext } from "react";
import { UserContext, UserContextType } from "./UserContext";

/**
 * @returns {{user: User, setUser: function, userLoading: boolean, userError: object, userAuthed: boolean, userUnauthed: boolean}}
 */
export function useUser(): UserContextType {
  return useContext(UserContext);
}

export default useUser;
