import React from "react";
import { SpendMonth } from "../../apiQueries/spend";
import MiniChart from "../MiniChart/MiniChart";

export type MiniSpendChartProps = {
  spend: SpendMonth[];
};

export default function MiniSpendChart({
  spend,
}: MiniSpendChartProps): React.JSX.Element {
  return MiniChart({
    data: spend.map((s) => ({ month: s.month, yValue: s.grossCharge })),
    label: "12MO SPEND",
    widthPx: 200,
    heightPx: 40,
  });
}
