import { Factory } from "fishery";
import { apiRequest } from "../modules/apiRequest";
import {
  Organization,
  organizationFactory,
  OrgRoleAccess,
} from "./organizations";

type UserRoleAccessOption = "read" | "write";
const rw: UserRoleAccessOption[] = ["read", "write"];
export type UserRoleAccess = {
  skywayAdmin: string[];
  reconciliation: string[];
};

export const userOrganizationFactory = Factory.define<UserOrganization>(() => ({
  organization: organizationFactory.build(),
  roleAccess: {
    orgsettings: rw,
    orgmembers: rw,
    curingest: rw,
    contractmanager: rw,
    contracts: rw,
    commitments: rw,
    billing: rw,
  },
  roleName: "some role",
}));
// An organization with extra information about the current user's access to it.
export type UserOrganization = {
  organization: Organization;
  roleAccess: OrgRoleAccess;
  roleName: string;
};

export type User = {
  adminUrl: string;
  userOrganizations: UserOrganization[];
  avatarSrc: string;
  email: string;
  id: number;
  invitedOrganizations: any[];
  name: string;
  userRoleAccess: UserRoleAccess;
  supervisingOrganizations?: Organization[];
};

export const userFactory = Factory.define<User>(() => ({
  adminUrl: "admin_url",
  userOrganizations: [userOrganizationFactory.build()],
  avatarSrc: "https://robohash.org/1?set=set1&bgset=bg1&size=200x200",
  email: "email",
  id: 1,
  invitedOrganizations: [],
  name: "Sam",
  userRoleAccess: {
    skywayAdmin: rw,
    reconciliation: rw,
  },
}));

export const getMe = () => apiRequest.get<User>("/api/v1/me");
