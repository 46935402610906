import React from "react";
import badContext from "../modules/badContext";

export type ColorMode = "light" | "dark";

export const DEFAULT_COLOR_MODE: ColorMode = "light";

export type GlobalViewStateContextType = {
  navElement: HTMLElement | null;
  setNavElement: (element: HTMLElement | null) => void;
  colorMode: ColorMode;
  setColorMode: (mode?: ColorMode) => void;
  invertedColorMode: ColorMode;
};

export const GlobalViewStateContext =
  React.createContext<GlobalViewStateContextType>({
    navElement: null,
    setNavElement: badContext("GlobalViewState"),
    colorMode: DEFAULT_COLOR_MODE,
    setColorMode: badContext("GlobalViewState"),
    invertedColorMode: "dark",
  });

export function invertColorMode(colorMode: ColorMode): ColorMode {
  return colorMode === "light" ? "dark" : "light";
}
