import { apiRequest } from "../modules/apiRequest";

type DashboardIssue = {
  key: string;
  message: string;
  cta: string;
  url: string;
};
type Dashboard = { issues: DashboardIssue[] };
export const getDashboard = ({
  orgExternalCode: orgExternalCode,
}: {
  orgExternalCode: string | undefined;
}) => {
  if (orgExternalCode) {
    return apiRequest.get<Dashboard>(
      `/api/v1/organizations/${orgExternalCode}/dashboard`,
    );
  } else {
    return apiRequest.get<Dashboard>(`/api/v1/dashboard`);
  }
};
