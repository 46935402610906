import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { useCurrentOrg } from "../state/useOrg";
import useUser from "../state/useUser";

// Maintain the LaunchDarkly context.  That is, let LD know who the current user
// is and what org they're viewing so that we can have it send us the correct
// set of feature flags whenever we use `useFlags()`.
export default function LDContextSetter(): React.JSX.Element {
  const launchDarklyClient = useLDClient();
  const { data: currentOrg } = useCurrentOrg(true);
  const { user } = useUser();

  useEffect(() => {
    if (!launchDarklyClient) return;

    // We use a "multi-context" so that our feature flag rules can target
    // specific users, specific orgs, or both.
    const context: LDContext = {
      kind: "multi",
    };
    if (user) {
      context.user = {
        key: user.id,
        email: user.email,
        name: user.name,
      };
    } else {
      context.user = {
        anonymous: true,
      };
    }

    if (currentOrg) {
      context.organization = {
        key: currentOrg.externalCode,
        name: currentOrg.name,
      };
    }

    console.log(
      `Identifying as ${context.user.key} | ${context.organization?.key}`,
      context,
    );
    launchDarklyClient.identify(context);
  }, [launchDarklyClient, currentOrg, user]);
  return <></>;
}
