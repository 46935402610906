import React from "react";
import { OrgRoleAccess } from "../apiQueries/organizations.js";
import { UserOrganization, UserRoleAccess } from "../apiQueries/users.js";
import { useCurrentOrg } from "./useOrg.js";
import useUser from "./useUser.jsx";

type orgRoleAccessKey = keyof OrgRoleAccess;
type userRoleAccessKey = keyof UserRoleAccess;
type validAccessKeys = orgRoleAccessKey | userRoleAccessKey;
export default function useRoleAccess(
  { forceReadAll } = { forceReadAll: false },
) {
  const { user } = useUser();
  const { data: currentOrg } = useCurrentOrg();
  const accessForCurrentOrg = user?.userOrganizations.find(
    (org: UserOrganization) =>
      org.organization.externalCode === currentOrg?.externalCode,
  );
  console.log("accessForCurrentOrg", accessForCurrentOrg);
  const orgRoleAccess = accessForCurrentOrg?.roleAccess;
  const userRoleAccess = user?.userRoleAccess;
  const canAccess = React.useCallback(
    (name: validAccessKeys, access: "read" | "write") => {
      if (!orgRoleAccess && !userRoleAccess) {
        return false;
      }
      return (
        orgRoleAccess?.[name as orgRoleAccessKey]?.includes(access) ||
        userRoleAccess?.[name as userRoleAccessKey]?.includes?.(access)
      );
    },
    [orgRoleAccess, userRoleAccess],
  );
  const canRead = React.useCallback(
    (name: validAccessKeys) => {
      return forceReadAll || canAccess(name, "read");
    },
    [forceReadAll, canAccess],
  );
  const canWrite = React.useCallback(
    (name: validAccessKeys) => canAccess(name, "write"),
    [canAccess],
  );
  const cannotWrite = React.useCallback(
    (name: validAccessKeys) => !canAccess(name, "write"),
    [canAccess],
  );

  const result = React.useMemo(
    () => ({ canAccess, canRead, canWrite, cannotWrite }),
    [canAccess, canRead, canWrite, cannotWrite],
  );
  return result;
}
