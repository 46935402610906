import { apiRequest } from "../modules/apiRequest";

export type SpendMonth = {
  month: string;
  grossCharge: number;
};

export const getSpendTrailing = ({
  trailingMonths,
  orgExternalCode,
}: {
  orgExternalCode: string;
  trailingMonths: number;
}) => {
  return apiRequest.get<SpendMonth[]>(
    `/api/v1/organizations/${orgExternalCode}/spend/trailing`,
    {
      params: { trailing_months: trailingMonths },
    },
  );
};
