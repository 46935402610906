import { apiRequest } from "../modules/apiRequest";
import { PaginationResponse } from "./commonTypes";

type OrganizationMember = {
  external_code: string;
  avatar_src: string;
  email: string;
  invited_at: string;
  name: string;
  organization_name: string;
  role: {
    key: string;
    name: string;
  };
  user_id: string;
};

export const getOrganizationMembers = ({
  orgExternalCode,
}: {
  orgExternalCode: string;
}) => {
  return apiRequest.get<PaginationResponse<OrganizationMember>>(
    `/api/v1/organizations/${orgExternalCode}/members`,
  );
};
