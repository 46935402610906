import { AppShell, Box, Group, Image } from "@mantine/core";
import React from "react";
import skywayLogo from "../../assets/images/skyway_logo.svg";

export default function HeaderV2(): React.JSX.Element {
  return (
    <AppShell.Header>
      <Group h="100%" gap="sm">
        <a href="/" style={{ textDecoration: "none" }}>
          <Group h="100%" px="lg" gap="sm">
            <Image src={skywayLogo} alt="Skyway logo" h="32" w="32" />
            <Box fw="bold">Skyway</Box>
          </Group>
        </a>
      </Group>
    </AppShell.Header>
  );
}
