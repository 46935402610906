import { createContext } from "react";
import { Toggle } from "./useToggle";

export const ScreenLoaderContext = createContext<Toggle>({
  isOn: false,
  isOff: true,
  setState: () => {},
  turnOn: () => {},
  turnOff: () => {},
  toggle: () => {},
});
