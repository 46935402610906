import {
  Anchor,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Paper,
  Stack,
  Title,
} from "@mantine/core";
import { useQueries, useQuery } from "@tanstack/react-query";
import { truncate, zipWith } from "lodash";
import React from "react";
import api from "../../api";
import { EdrMonth } from "../../apiQueries/edr";
import { Organization } from "../../apiQueries/organizations";
import { SpendMonth } from "../../apiQueries/spend";
import { appHref } from "../../app/appHref";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import MiniEdrChart from "../../components/MiniEdrChart/MiniEdrChart";
import MiniSpendChart from "../../components/MiniSpendChart/MiniSpendChart";
import ComponentLoader from "../../components/uikit/ComponentLoader";
import { helpEmail } from "../../modules/contacts";
import { orgPath, useRedirectToNonOrgRoute } from "../../state/useOrg";

type OrgRowProps = {
  organization: Organization;
  trailingSpend: SpendMonth[];
  trailingEdr: EdrMonth[];
};
function OrgRow({
  organization,
  trailingSpend,
  trailingEdr,
}: OrgRowProps): React.JSX.Element {
  return (
    <Paper shadow="md" py="md" px="xl" maw={1200} miw={800}>
      <Grid align="center">
        <Grid.Col span={4}>
          <Title order={3}>{truncate(organization.name, { length: 24 })}</Title>
        </Grid.Col>
        <Grid.Col span={3}>
          <MiniSpendChart spend={trailingSpend} />
        </Grid.Col>
        <Grid.Col span={3}>
          <MiniEdrChart edr={trailingEdr} />
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex justify="flex-end">
            <Button
              variant="light"
              component="a"
              href={appHref(orgPath("/dashboard", organization.externalCode))}
            >
              View
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

export type SupervisedOrganizationsPagePresentationProps = {
  organizationRowData: OrganizationRowData[];
};
export function SupervisedOrganizationsPagePresentation({
  organizationRowData,
}: SupervisedOrganizationsPagePresentationProps): React.JSX.Element {
  return (
    <LayoutV2>
      {organizationRowData.length === 0 ? (
        <Center mih={300}>
          <Stack>
            <Title order={2}>No supervised organizations</Title>
            <Box maw={500} lh="md">
              Your organization is still being set up. Check back later to view
              the organizations you&apos;re supervising. Email us at{" "}
              <Anchor href={`mailto:${helpEmail}`}>{helpEmail}</Anchor> if you
              have questions.
            </Box>
          </Stack>
        </Center>
      ) : (
        <Stack p="lg" gap="lg" mih="calc(100vh - 60px)">
          {organizationRowData.map(
            ({ organization, trailingSpend, trailingEdr }) => {
              return (
                <OrgRow
                  key={organization.externalCode}
                  organization={organization}
                  trailingSpend={trailingSpend}
                  trailingEdr={trailingEdr}
                />
              );
            },
          )}
        </Stack>
      )}
    </LayoutV2>
  );
}

type OrganizationRowData = {
  organization: Organization;
  trailingSpend: SpendMonth[];
  trailingEdr: EdrMonth[];
};
export default function SupervisedOrganizationsPage(): React.JSX.Element {
  useRedirectToNonOrgRoute("/supervised-organizations");

  const { data: organizationsResponse } = useQuery({
    queryFn: api.getSupervisedOrganizations,
    queryKey: ["supervisedOrganizations"],
  });
  const spendQueryResults = useQueries({
    queries: (organizationsResponse?.items || []).map((organization) => ({
      queryFn: () =>
        api.getSpendTrailing({
          orgExternalCode: organization.externalCode,
          trailingMonths: 12,
        }),
      queryKey: [
        "spendTrailing",
        { orgExternalCode: organization.externalCode, trailingMonths: 12 },
      ],
      enabled: !!organizationsResponse,
    })),
  });

  const edrQueryResults = useQueries({
    queries: (organizationsResponse?.items || []).map((organization) => ({
      queryFn: () =>
        api.getEdrTrailing({
          orgExternalCode: organization.externalCode,
          trailingMonths: 12,
        }),
      queryKey: [
        "edrTrailing",
        { orgExternalCode: organization.externalCode, trailingMonths: 12 },
      ],
      enabled: !!organizationsResponse,
    })),
  });

  if (!organizationsResponse)
    return (
      <LayoutV2>
        <Center mih={300}>
          <ComponentLoader />
        </Center>
      </LayoutV2>
    );

  const organizationRowData = zipWith(
    organizationsResponse.items,
    spendQueryResults.map((result) => result.data),
    edrQueryResults.map((result) => result.data),
    (organization, trailingSpend, trailingEdr) => {
      return {
        organization,
        trailingSpend: trailingSpend ?? [],
        trailingEdr: trailingEdr ?? [],
      };
    },
  );
  return (
    <SupervisedOrganizationsPagePresentation
      organizationRowData={organizationRowData}
    />
  );
}
