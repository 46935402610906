import {
  Box,
  Button,
  Checkbox,
  Group,
  Paper,
  Popover,
  Stack,
  Text,
} from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import React, { useMemo } from "react";
import { useToggle } from "../../../../state/useToggle";

import { AVAILABLE_COLUMNS } from "../constants";
import type { ChartColumn, MonthlyComparisonChartData } from "../types";

interface CostAreaChartProps {
  data: MonthlyComparisonChartData[];
  selectedColumns: ChartColumn[];
  onColumnsChange: (columns: ChartColumn[]) => void;
}

export const CostAreaChart: React.FC<CostAreaChartProps> = ({
  data,
  selectedColumns,
  onColumnsChange,
}) => {
  const popoverToggle = useToggle(false);

  const chartData = useMemo(
    () =>
      data
        .map((item) => {
          const [year, month] = item.month.split("-").map(Number);
          const date = new Date(year, month - 1);

          const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "short",
            year: "numeric",
          }).format(date);

          const rowData: Record<string, unknown> = {
            date: formattedDate,
          };

          selectedColumns.forEach((column) => {
            rowData[column.label] = Number(item[column.key] || 0);
          });

          return rowData;
        })
        .sort((a, b) => {
          const [yearA, monthA] = (a.date as string).split(" ");
          const [yearB, monthB] = (b.date as string).split(" ");
          const dateA = new Date(Date.parse(`${monthA} 1, ${yearA}`));
          const dateB = new Date(Date.parse(`${monthB} 1, ${yearB}`));
          return dateA.getTime() - dateB.getTime();
        }),
    [data, selectedColumns],
  );

  const startDate = chartData[0]?.date;
  const endDate = chartData[chartData.length - 1]?.date;
  const dateRange = startDate && endDate ? `${startDate} - ${endDate}` : "";

  const allSelected = selectedColumns.length === AVAILABLE_COLUMNS.length;
  const someSelected = selectedColumns.length > 0 && !allSelected;

  const handleSave = () => {
    onColumnsChange(selectedColumns);
    popoverToggle.turnOff();
  };

  const handlePopoverOpen = () => {
    popoverToggle.turnOn();
  };

  return (
    <Paper p="md" shadow="sm">
      <Stack gap="md">
        <Group justify="space-between" align="center">
          <Text size="sm">Showing data for &quot;{dateRange}&quot;</Text>
          <Popover
            opened={popoverToggle.isOn}
            onChange={popoverToggle.setState}
            position="bottom-end"
            withArrow
            width={300}
          >
            <Popover.Target>
              <Button
                variant="subtle"
                leftSection={<FontAwesomeIcon icon={faPencil} />}
                onClick={handlePopoverOpen}
                size="sm"
              >
                Customize graph
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack>
                <Group justify="space-between" align="center">
                  <Text fw={500}>Showing</Text>
                  <Checkbox
                    label={allSelected ? "Uncheck All" : "Check All"}
                    checked={allSelected}
                    indeterminate={someSelected}
                    onChange={(event) => {
                      onColumnsChange(
                        event.currentTarget.checked ? AVAILABLE_COLUMNS : [],
                      );
                    }}
                  />
                </Group>
                <Box style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <Stack>
                    {AVAILABLE_COLUMNS.map((column) => (
                      <Checkbox
                        key={column.key}
                        checked={selectedColumns.some(
                          (c) => c.key === column.key,
                        )}
                        onChange={(event) => {
                          if (event.currentTarget.checked) {
                            onColumnsChange([...selectedColumns, column]);
                          } else {
                            onColumnsChange(
                              selectedColumns.filter(
                                (c) => c.key !== column.key,
                              ),
                            );
                          }
                        }}
                        label={
                          <Group gap="xs">
                            <Box
                              w={12}
                              h={12}
                              style={{
                                backgroundColor: column.color,
                                borderRadius: "50%",
                              }}
                            />
                            <Text size="sm">{column.label}</Text>
                          </Group>
                        }
                      />
                    ))}
                  </Stack>
                </Box>
                <Button variant="light" color="violet" onClick={handleSave}>
                  Apply
                </Button>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        </Group>

        <Group>
          {selectedColumns.map((column) => (
            <Group key={column.key} gap="xs">
              <Box
                w={8}
                h={8}
                style={{
                  backgroundColor: column.color,
                  borderRadius: "50%",
                }}
              />
              <Text size="sm">{column.label}</Text>
            </Group>
          ))}
        </Group>

        <AreaChart
          h={300}
          data={chartData}
          dataKey="date"
          series={selectedColumns.map((column) => ({
            name: column.label,
            color: column.color,
          }))}
          curveType="monotone"
          withLegend={false}
          withTooltip
          valueFormatter={(value: number) =>
            `$${Number(value).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          }
          withXAxis
          withYAxis
          gridAxis="xy"
          tickLine="xy"
          yAxisProps={{
            domain: ["auto", "auto"],
            tickFormatter: (value: number) =>
              `$${value.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`,
          }}
          xAxisProps={{
            tickMargin: 10,
            padding: { left: 20, right: 20 },
          }}
          areaChartProps={{
            margin: { top: 40, right: 40, bottom: 20, left: 70 },
          }}
        />
      </Stack>
    </Paper>
  );
};
