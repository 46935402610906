import { ComponentType } from "react";
import { Helmet } from "react-helmet-async";

interface MetatagsProps {
  title: string;
  exact?: boolean;
}

export default function withMetatags({ title, exact }: MetatagsProps) {
  const customTitle = title ? `${title} | Skyway` : "Skyway";

  return function <P extends object>(
    Wrapped: ComponentType<P>,
  ): ComponentType<P> {
    return function WithMetatags(props: P) {
      return (
        <>
          <Helmet>
            <title>{exact ? title : customTitle}</title>
          </Helmet>
          <Wrapped {...props} />
        </>
      );
    };
  };
}
