import { createContext } from "react";

export interface ClientsideSearchParams {
  searchParams: URLSearchParams;
  replaceSearchParams: (arg: URLSearchParams | Record<string, string>) => void;
  setSearchParam: (key: string, value: string) => void;
}

export const ClientsideQueryParamsContext =
  createContext<ClientsideSearchParams>({
    searchParams: new URLSearchParams(),
    replaceSearchParams: () => {},
    setSearchParam: () => {},
  });
