import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Tooltip, useMantineTheme } from "@mantine/core";
import React from "react";
import { EdrMonth } from "../../apiQueries/edr";
import MiniChart from "../MiniChart/MiniChart";

export type MiniEdrChartProps = {
  edr: EdrMonth[];
};

export default function MiniEdrChart({
  edr,
}: MiniEdrChartProps): React.JSX.Element {
  const theme = useMantineTheme();
  return (
    <Box style={{ position: "relative" }}>
      <MiniChart
        data={edr.map((e) => ({
          month: e.month,
          // Convert the effective discount rate to a percentage
          yValue: e.effectiveDiscountRate * 100,
        }))}
        label="EFFECTIVE DISCOUNT RATE"
        widthPx={200}
        heightPx={40}
        hoverTemplate="%{y:.1f}%<extra>%{x}</extra>"
      />
      <Tooltip
        label=<Box maw={300} py={4} px={6}>
          Your combined discount rate across all spend, accounting for
          contracted discounts and credits.
        </Box>
        multiline
      >
        <FontAwesomeIcon
          icon={faCircleInfo}
          size="xs"
          color={theme.colors.gray[4]}
          style={{ position: "absolute", bottom: 1, right: 2 }}
        />
      </Tooltip>
    </Box>
  );
}
