import React from "react";
import { useLocation } from "react-router-dom";
import {
  ClientsideQueryParamsContext,
  ClientsideSearchParams,
} from "./ClientsideQueryParamsContext";

interface Props {
  children: React.ReactNode;
}

export function ClientsideQueryParamsProvider({ children }: Props) {
  const location = useLocation();
  const [searchParamsState, setSearchParamsState] = React.useState(
    new URLSearchParams(window.location.search),
  );

  React.useEffect(() => {
    setSearchParamsState(new URLSearchParams(location.search));
  }, [location]);

  const replaceSearchParams = React.useCallback(
    (arg: URLSearchParams | Record<string, string>) => {
      const params =
        arg instanceof URLSearchParams ? arg : new URLSearchParams(arg);
      const url = new URL(window.location.href);
      url.search = params.toString();
      window.history.pushState({}, "", url);
      setSearchParamsState(params);
    },
    [],
  );

  const setSearchParam = React.useCallback(
    (k: string, val: string) => {
      const newparams = new URLSearchParams(searchParamsState);
      newparams.set(k, val);
      replaceSearchParams(newparams);
    },
    [replaceSearchParams, searchParamsState],
  );

  const value: ClientsideSearchParams = React.useMemo(
    () => ({
      searchParams: searchParamsState,
      replaceSearchParams,
      setSearchParam,
    }),
    [replaceSearchParams, searchParamsState, setSearchParam],
  );

  return (
    <ClientsideQueryParamsContext.Provider value={value}>
      {children}
    </ClientsideQueryParamsContext.Provider>
  );
}

export default ClientsideQueryParamsProvider;
