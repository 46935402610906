import React, { useEffect } from "react";
import ScreenLoader from "../components/uikit/ScreenLoader";
import signOut from "../modules/signOut";
import useErrorToast from "../state/useErrorToast";
import { clearCurrentOrgExternalCode } from "../state/useOrg";

export default function SignOutPage(): React.JSX.Element {
  const { showErrorToast } = useErrorToast();
  useEffect(() => {
    clearCurrentOrgExternalCode();
    signOut()
      .catch(showErrorToast)
      .then(() => {
        window.location.href = "/";
      });
  });
  return <ScreenLoader />;
}
